import "./styles.css";
import usePartySocket from "partysocket/react";
import { useState } from "react";
import { createRoot } from "react-dom/client";

function App() {
  const [messages, setMessages] = useState<string[]>([]);
  const socket = usePartySocket({
    room: "example-room",
    onMessage(evt) {
      console.log("Received message:", evt.data);
      setMessages((prev) => [...prev, evt.data]);
    },
  });
  // const socket = usePartySocket({
  //   room: "example-room",
  //   onMessage(evt) {
  //     console.log("Received message:", evt.data);
  //   },
  // });
  return (
    <>
      <h1>🎈 Welcome to PartyKit!</h1>
      <button onClick={() => socket.send("hello from client")}>
        Send message to server
      </button>
      <ul>
        {messages.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </ul>
    </>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
